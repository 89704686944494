import React from 'react';
import PropTypes from 'prop-types';
import { Uploader } from '../../../../uploader';
import { setButtonsWrapperClassName } from '../../utils';
import UploadProgress from '../../../UploadMediaSection/components/UploadProgress';
import CtaButton from '../../../shared/CtaButton';

function Preview({
  blob,
  uploadUrl,
  askerSide,
  resetToInitial,
  ctaButtonStyle,
  ctaButtonContent,
  uploadCreateCallback,
}) {
  const progressContainerRef = React.useRef(null);
  const progressBarRef = React.useRef(null);
  const progressPercentageRef = React.useRef(null);
  const uploadButtonsContainerRef = React.useRef(null);

  let activeUpload;
  const setActiveUploadRequest = (request) => {
    activeUpload = request;
  };

  const cancelUpload = () => {
    if (activeUpload) {
      activeUpload.abort();
    }

    resetToInitial();
  };

  const handleUploadMedia = () => {
    progressContainerRef.current.classList.remove('hidden');
    uploadButtonsContainerRef.current.classList.add('hidden');
    const upload = new Uploader(
      blob,
      uploadUrl,
      progressPercentageRef.current,
      progressBarRef.current,
      setActiveUploadRequest,
    );

    upload.create(uploadCreateCallback);
  };
  return (
    <>
      <UploadProgress
        containerRef={progressContainerRef}
        percentageRef={progressPercentageRef}
        progressBarRef={progressBarRef}
        cancelUpload={cancelUpload}
      />
      <div
        ref={uploadButtonsContainerRef}
        className={setButtonsWrapperClassName(askerSide)}
      >
        <button
          className="button media-background__button media-background__button--redo"
          type="button"
          data-testid="redo-button"
          onClick={resetToInitial}
        />
        <CtaButton
          className="media-background__button"
          data-testid="confirm-upload-button"
          onClick={handleUploadMedia}
          ctaButtonStyle={ctaButtonStyle}
          ctaButtonContent={ctaButtonContent || 'Save Changes'}
        />
      </div>
    </>
  );
}

Preview.propTypes = {
  blob: PropTypes.object,
  uploadUrl: PropTypes.string.isRequired,
  askerSide: PropTypes.bool,
  resetToInitial: PropTypes.func.isRequired,
  uploadCreateCallback: PropTypes.func.isRequired,
  ctaButtonContent: PropTypes.string,
};

export default Preview;
