import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import RecordRTC from 'recordrtc';
import VideoBackground from '../responder/videoplayer/VideoBackground';
import ButtonsSection from './components/ButtonsSection';
import { isMobile } from '../../utils';
import { setMediaWrapperClassNames, setVideoTagClassNames } from './utils';

export const INITIAL = 'initial';
export const COUNTDOWN = 'countdown';
export const RECORDING = 'recording';
export const PREVIEW = 'preview';

function VideoRecord({
  uuid,
  cancelPath,
  directUploadUrl,
  backgroundIsColor,
  backgroundLayout,
  ctaButtonContent,
  ctaButtonStyle,
  askerSide,
}) {
  const [recordingState, setRecordingState] = React.useState(INITIAL);
  const [blob, setBlob] = React.useState(null);

  const fileInputRef = React.useRef(null);
  const videoRef = useRef(null);
  const videoStream = useRef(null);
  const recorder = useRef(null);

  useEffect(() => {
    if (isMobile()) {
      if (fileInputRef && fileInputRef.current) {
        fileInputRef.current.addEventListener('change', fileUploadListener);

        return () => {
          fileInputRef.current.removeEventListener(
            'change',
            fileUploadListener,
          );
        };
      }
    } else {
      captureCamera();
    }
  }, []);

  useEffect(() => {
    if (recordingState === RECORDING) {
      recorder.current = RecordRTC(videoStream.current, {
        type: 'video',
        bitsPerSecond: 25600000,
        canvas: { width: 500, height: 800 },
      });

      recorder.current.startRecording();
    }
  }, [recordingState]);

  const fileUploadListener = (e) => {
    setRecordingState(PREVIEW);
    setBlob(e.target.files[0]);
  };

  const captureCamera = () => {
    navigator.mediaDevices
      .getUserMedia({
        audio: true,
        video: true,
      })
      .then((stream) => {
        videoStream.current = stream;
        if (videoRef.current) {
          videoRef.current.muted = true;
          videoRef.current.volume = 0;
          videoRef.current.srcObject = stream;
        }
      });
  };

  const startRecording = () => {
    if (isMobile()) {
      if (fileInputRef && fileInputRef.current) {
        fileInputRef.current.click();
      }
    } else if (videoStream.current) {
      setRecordingState(COUNTDOWN);
    }
  };

  const stopRecordingCallback = () => {
    const videoBlob = recorder.current.getBlob();
    videoBlob.name = 'video-record-response';
    setBlob(videoBlob);
    setRecordingState(PREVIEW);

    recorder.current.destroy();
    recorder.current = null;
  };

  const stopRecording = () => {
    recorder.current.stopRecording(stopRecordingCallback);
  };

  const resetToInitial = () => {
    setRecordingState(INITIAL);
    setBlob(null);
  };

  const closeRecordPage = () => {
    if (window.Turbo) {
      Turbo.visit(cancelPath);
    } else {
      window.location.href = cancelPath;
    }
  };

  return (
    <>
      {askerSide && <p className="media-background__subtitle">Upload media</p>}
      <input
        ref={fileInputRef}
        className="hidden"
        type="file"
        accept="video/*"
        capture="user"
      />
      <div className={setMediaWrapperClassNames(askerSide, backgroundIsColor)}>
        <video
          ref={videoRef}
          autoPlay
          playsInline
          className={setVideoTagClassNames(askerSide, backgroundLayout)}
        />
        {blob && (
          <div data-testid="VideoBackground/Upload">
            <VideoBackground
              sources={[URL.createObjectURL(blob)]}
              backgroundIsColor={backgroundIsColor}
              backgroundLayout={backgroundLayout}
            />
          </div>
        )}
      </div>
      <div className={askerSide && 'media-background__controls'}>
        <ButtonsSection
          blob={blob}
          uuid={uuid}
          askerSide={askerSide}
          recordingState={recordingState}
          setRecordingState={setRecordingState}
          startRecording={startRecording}
          stopRecording={stopRecording}
          resetToInitial={resetToInitial}
          closeRecordPage={closeRecordPage}
          uploadUrl={directUploadUrl}
          ctaButtonContent={ctaButtonContent}
          ctaButtonStyle={ctaButtonStyle}
        />
      </div>
    </>
  );
}

VideoRecord.propTypes = {
  uuid: PropTypes.string.isRequired,
  cancelPath: PropTypes.string.isRequired,
  directUploadUrl: PropTypes.string.isRequired,
  backgroundIsColor: PropTypes.bool,
  backgroundLayout: PropTypes.string,
  ctaButtonContent: PropTypes.string,
  askerSide: PropTypes.bool,
};

export default VideoRecord;
